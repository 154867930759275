<!--
 * @Description: 二维码上传记录
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-26 15:25:27
-->
<template>
      <el-scrollbar ref="scrollbar">
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true" @submit.prevent="onSearch">
      <el-form-item label="单号">
        <el-input
          v-model="query.no"
          placeholder="报备单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="客户">
        <el-input
          v-model="query.q"
          placeholder="姓名、手机号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="报备人">
        <el-input
          v-model="query.consumer"
          placeholder="报备人姓名、手机号"
          style="width: 220px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="带看人">
        <el-input
          v-model="query.daikan"
          placeholder="带看人姓名、手机号"
          style="width: 220px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="报备日期">
        <el-date-picker
          v-model="query.dates"
          type="daterange"
          value-format="YYYY-MM-DD"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="公司分类">
        <el-select
          v-model="query.is_deyou"
          placeholder
          clearable
          style="width: 150px"
        >
          <el-option label="德佑" :value="true"></el-option>
          <el-option label="分销" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder
          clearable
          style="width: 150px"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in enums.report_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search" native-type="submit"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div></div>
  </el-card>

  <el-card class="box data report_data" v-loading="loading"> 
      <div class="grid" v-if="tableData.counts">
        <div class="item" v-for="(item, index) in tableData.data" :key="index">
          <el-card shadow="hover">
            <el-descriptions :column="1">
              <el-descriptions-item label="系统编号">{{
                item.id
              }}</el-descriptions-item>
              <el-descriptions-item label="报备单号">{{
                item.hNumber
              }}</el-descriptions-item>
              <el-descriptions-item label="公司名称">{{
                item.company
              }}</el-descriptions-item>
              <el-descriptions-item label="带看项目">{{
                item.estateName
              }}</el-descriptions-item>
              <el-descriptions-item label="带看人"
                >{{ item.daikanName }}/{{
                  item.daikanMobile
                }}</el-descriptions-item
              >
              <el-descriptions-item label="报备人"
                >{{ item.consumer_Name }}/{{
                  item.consumer_Mobile
                }}</el-descriptions-item
              >
              <el-descriptions-item label="客户姓名">{{
                item.clientName
              }}</el-descriptions-item>
              <el-descriptions-item label="客户性别">{{
                item.sex
              }}</el-descriptions-item>
              <el-descriptions-item label="客户手机">{{
                item.clientMobile
              }}</el-descriptions-item>
              <el-descriptions-item label="到案人数">{{
                item.dueNum
              }}</el-descriptions-item>
              <el-descriptions-item label="到案时间">{{
                item.dueTime
              }}</el-descriptions-item>
              <el-descriptions-item label="报备备注">{{
                item.comment
              }}</el-descriptions-item>
              <el-descriptions-item label="报备时间">{{
                item.creationTime
              }}</el-descriptions-item>
              <el-descriptions-item label="状态">
                <el-link
                  type="warning"
                  :underline="false"
                  v-if="item.curStatus == 0"
                  >{{ item.cur_status_text }}</el-link
                >
                <el-link
                  type="primary"
                  :underline="false"
                  v-else-if="item.curStatus == 1"
                  >{{ item.cur_status_text
                  }}{{ item.unknown ? "(结果未知)" : "" }}</el-link
                >
                <el-link
                  type="success"
                  :underline="false"
                  v-else-if="item.curStatus == 2"
                  >{{ item.cur_status_text }}</el-link
                >
                <el-link
                  type="danger"
                  :underline="false"
                  v-else-if="item.curStatus == 3"
                  >{{ item.cur_status_text }}</el-link
                >
                <el-link :underline="false" v-else>{{
                  item.cur_status_text
                }}</el-link>
              </el-descriptions-item>
              <el-descriptions-item label="处理人"
                >{{ item.modify_name }}{{ item.modifiedTime }}

                <!-- <span v-if="item.isAcData"
                  >{{ item.kefu_name }}({{ item.kefu_mobile }})</span
                > -->
              </el-descriptions-item>
              <el-descriptions-item label="结果备注">{{
                item.resultContent
              }}</el-descriptions-item>
              <el-descriptions-item label="带看二维码">
                <div
                  v-if="item.estate.isGetQRCode"
                  style="display: flex; flex-direction: row"
                >
                  <el-image
                    :src="item.qrCodeUrl"
                    fit="cover"
                    :initial-index="1"
                    :preview-src-list="[item.qrCodeUrl]"
                    style="width: 60px; height: 60px; margin-right: 10px"
                  ></el-image>
                  <div style="margin-left: 10px">
                    <div><b>已上传</b></div>
                    <div>申请时间：{{ item.getQRCodeTime }}</div>
                    <div>
                      {{ item.qrCodeUploaderName }}{{ item.uploadQRCodeTime }}
                    </div>
                  </div>
                </div>
                <span v-else style="display: inline-block; height: 55px"
                  >项目无二维码</span
                >
              </el-descriptions-item>
              <el-descriptions-item>
                <el-button
                  type="info"
                  size="small"
                  icon="upload"
                  v-if="item.estate.isGetQRCode"
                  @click="onUploadQrCode(item)"
                  >上传二维码</el-button
                >
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </div>
      </div>
      <el-empty v-if="!loading && !tableData.counts"></el-empty> 

    <el-pagination
      v-if="tableData.counts"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</el-scrollbar>
  <upload-qr-code
    :item="current_qr_item"
    @closed="current_qr_item = null"
    @success="loadData"
  ></upload-qr-code>
</template>

<script>
import UploadQrCode from "../newqrcode/upload_qrcode.vue";

import common_api from "@/http/common_api";
export default {
  components: {
    UploadQrCode,
  },
  data() {
    return {
      loading: false,
      visibleDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      enums: {},
      tableData: {
        counts: 0,
      },
      current_qr_item: null,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
      this.$refs.scrollbar.setScrollTop(0);
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
      this.$refs.scrollbar.setScrollTop(0);
    },

    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("/admin/v1/reportQRCode", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 上传二维码
     * @param {*} item
     */
    onUploadQrCode(item) {
      this.current_qr_item = item;
    },

    /**
     * 忽略申请二维码
     * @param {*} item
     */
    onCancel(item) {
      item.saving = true;
      this.$http
        .get("admin/v1/reportQRCode/cancel?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.loadData();
          }
        })
        .finally(() => (item.saving = false));
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 10px;
}

.att_image_preview {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}
</style>

<style>
.report_data .el-descriptions__cell {
  padding-bottom: 2px !important;
}
.report_data .el-descriptions__label {
  color: #999;
}
</style>
